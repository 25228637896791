import { Text } from "@clipboard-health/ui-react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, IconButton } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { intersection } from "lodash";
import { type ReactElement } from "react";
import { useHistory } from "react-router-dom";

import { useAgentProfile } from "../Agents/api/useAgentProfile";
import { CbhFeatureFlag, useCbhFlag } from "../FeatureFlags";
import {
  APP_V2_USER_EVENTS,
  AppBarHeader,
  AppLoadingPage,
  NotFoundPage,
  PageWithHeader,
  useToast,
} from "../lib";
import { useLogEffect } from "../lib/analytics/useLogEffect";
import { useUpdateWorkerAvailabilities } from "./api/useUpdateWorkerAvailability";
import { WorkerAvailabilityForm } from "./components/WorkerAvailabilityForm/WorkerAvailabilityForm";
import { AVAILABILITIES_PATH } from "./constants";
import { useWorkerAvailabilityContext } from "./context";
import { getAvailableDateStringsFromCurrentDates } from "./utils";

export function WorkerAvailabilityPage(): ReactElement {
  const { data: agentProfile, isLoading: isAgentProfileLoading } = useAgentProfile();
  const agentAvailableQualifications =
    agentProfile?.licensesData?.map((item) => item.qualification) ?? [];
  const { qualifications } = useCbhFlag(CbhFeatureFlag.ENABLE_WORKER_AVAILABILITY, {
    defaultValue: { qualifications: [] },
  });
  const isWorkerAvailabilityEnabled =
    intersection(qualifications, agentAvailableQualifications).length > 0;

  const history = useHistory();
  const queryClient = useQueryClient();
  const { showErrorToast, showSuccessToast } = useToast();
  const {
    currentDates,
    isLoading: isWorkerAvailabilityLoading,
    timeZone,
    calendarOptions,
  } = useWorkerAvailabilityContext();

  const { isLoading: isSavingAvailabilities, mutate: updateAvailabilities } =
    useUpdateWorkerAvailabilities({
      onSuccess(_response) {
        void queryClient.invalidateQueries([AVAILABILITIES_PATH]);
        showSuccessToast("Your availability has been saved!");
        history.push("/home/openShifts");
      },
      onError() {
        showErrorToast("Something went wrong while saving your availabilities");
      },
    });

  useLogEffect(APP_V2_USER_EVENTS.AVAILABILITY_CALENDAR_VIEWED);

  if (isAgentProfileLoading) {
    return <AppLoadingPage />;
  }

  if (!isWorkerAvailabilityEnabled) {
    return <NotFoundPage />;
  }

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Availability"
          leftCta={
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="Close"
              title="Close"
              onClick={() => {
                history.push("/home/openShifts");
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          }
          rightCta={
            isSavingAvailabilities || isWorkerAvailabilityLoading ? (
              <CircularProgress color="inherit" sx={{ marginLeft: 0.5 }} size="1.5rem" />
            ) : (
              <IconButton
                size="medium"
                edge="end"
                color="inherit"
                aria-label="Save"
                title="Save"
                onClick={() => {
                  updateAvailabilities({
                    tmz: timeZone,
                    startDate: format(calendarOptions.minDate, "yyyy-MM-dd"),
                    endDate: format(calendarOptions.maxDate, "yyyy-MM-dd"),
                    availableDates: getAvailableDateStringsFromCurrentDates(currentDates),
                  });
                }}
              >
                <CheckIcon fontSize="medium" />
              </IconButton>
            )
          }
        />
      }
    >
      <Text textAlign="center" marginBottom={3}>
        Let workplaces know when you&apos;re available by tapping on the dates below. You&apos;ll be
        notified as soon as shifts are posted.
      </Text>
      <WorkerAvailabilityForm isSubmitting={isSavingAvailabilities} />
    </PageWithHeader>
  );
}
